import React, { useEffect, useMemo, useCallback } from "react";

import format from "date-fns/format";

import { getStickyFilters } from "../../helpers/stickyfilters";
import { FiltersContext } from "../../context/FiltersContext";

import Badge from "@material-ui/core/Badge";
import IconFilter from "@material-ui/icons/FilterList";

import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import isNaN from "lodash/isNaN";
import { isNullOrUndef } from "../../helpers/isNullOrUndef";

class AutoCompleteFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error) {
    console.log("getDerivedStateFromError error => ", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("componentDidCatch Error => ", error);
    console.log("componentDidCatch Info => ", errorInfo);

    this.setState({ hasError: true });
  }

  handleError = (error, columnName) => {
    console.log("Error found on column => ", columnName);
    console.log("Error message => ", error);
    if (!this.state.hasError) {
      this.setState({ hasError: true, error: error });
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <IconFilter style={{ margin: "3px", color: "red" }} />
        </div>
      );
    }
    return <AutoCompleteFilterWrapper {...this.props} handleError={this.handleError} />;
  }
}

const AutoCompleteFilterWrapper = (props) => {
  const { toggleFilters, setFilterOptions, selectedFilters, setOptions, localFilters, setLocalFilters, setSelectedFilters, setReload, reload } = React.useContext(FiltersContext);

  const key = useMemo(() => {
    try {
      return `${props.section}_${props.column.key}`;
    } catch (error) {
      props.handleError(error, props.column.key);
    }
  }, [props.section, props.column.key]);

  const getOptions = useCallback(
    (unfiltered) => {
      try {
        const fieldname = props.fieldname;
        const filterKeys = Object.keys(props.currentFilters);
        const filterKeyIndex = filterKeys.indexOf(fieldname);

        /* return all data if no filters are applied or if first filter */
        if (filterKeys.length === 0 || filterKeyIndex == 0) {
          return unfiltered;
        }

        let filtered = [...unfiltered];

        filterKeys.forEach((key, indx) => {
          // filter from unfiltered data
          if (indx == 0) {
            filtered = filtered.filter((item) => getMatch(item, key));
          }
          // new filter, apply all filters
          if (filterKeyIndex == -1) {
            filtered = filtered.filter((item) => getMatch(item, key));
          }
          // existing filter, only apply previous filters
          else if (filterKeyIndex > indx) {
            filtered = filtered.filter((item) => getMatch(item, key));
          }
        });

        return filtered;
      } catch (error) {
        props.handleError(error, props.column.key);
      }
    },
    [props],
  );

  const dateSort = useCallback(
    (filterOptions) => {
      try {
        return filterOptions
          .filter((opt) => opt.value != "")
          .sort((a, b) => {
            const dateA = new Date(a.value || "").getTime();
            const dateB = new Date(b.value || "").getTime();
            if (props.orderDescending) {
              return dateB - dateA;
            }
            return dateA - dateB;
          })
          .map((opt) => ({ value: opt.value, label: format(parseISO(opt.label), "dd MMM yyyy") }));
      } catch (error) {
        props.handleError(error, props.column.key);
      }
    },
    [props],
  );

  const defaultSort = useCallback(
    (filterOptions) => {
      try {
        if (props.orderDescending) {
          return filterOptions.sort((a, b) => {
            if (a.value.toString().toLocaleLowerCase() < b.value.toString().toLocaleLowerCase()) return 1;
            if (a.value.toString().toLocaleLowerCase() > b.value.toString().toLocaleLowerCase()) return -1;
            return 1;
          });
        }
        return filterOptions.sort((a, b) => {
          if (a.value.toString().toLocaleLowerCase() > b.value.toString().toLocaleLowerCase()) return 1;
          if (a.value.toString().toLocaleLowerCase() < b.value.toString().toLocaleLowerCase()) return -1;
          return 1;
        });
      } catch (error) {
        props.handleError(error, props.column.key);
      }
    },
    [props],
  );

  const sortOptions = useCallback(
    (filterOptions) => {
      try {
        const nonEmptyFilterValue = (filterOptions.find((item) => item.value != "") || { value: "" }).value.toString().split(" ").join("");

        const isNotNumber = isNaN(Number(nonEmptyFilterValue));
        const isValidDate = isValid(parseISO(nonEmptyFilterValue));

        let filtered = [];

        /* when date sort check fails, default to string sort */
        try {
          if (isNotNumber && isValidDate) {
            filtered = dateSort(filterOptions);
            filtered = filtered.filter((item) => {
              if (item.label != "01 Jan 1900") {
                return item;
              }
            });
          } else {
            filtered = defaultSort(filterOptions);
          }
        } catch (error) {
          filtered = defaultSort(filterOptions);
        }
        filtered.splice(0, 0, { value: "", label: "BLANK" }, { value: "NON_BLANK", label: "NON BLANK" });
        return filtered;
      } catch (error) {
        props.handleError(error, props.column.key);
      }
    },
    [dateSort, defaultSort, props],
  );

  const setFilters = useCallback(
    (columnName) => {
      try {
        const key = props.fieldname;
        let options = getOptions(props.data);

        const filterOptions = options.reduce((arr, curr) => {
          if (isNullOrUndef(curr[key]) || curr[key].toString().trim() == "") {
            return arr;
          }

          const value = curr[key].toString().trim();

          if (
            props.fieldname == "producer_codes" ||
            props.fieldname == "saleinvoicenumbers" ||
            props.fieldname == "edivalidationerrors" ||
            props.fieldname == "edicomparisonerror" ||
            props.fieldname == "paymentcolumn" ||
            props.fieldname == "portdistcharge_code_special"
          ) {
            const values = value.split(", ");
            values.forEach((item) => {
              arr[item] = { label: item, value: item };
            });
          } else {
            arr[value] = { label: value, value: curr[key].toString().trim() };
          }

          return arr;
        }, {});

        const sorted = sortOptions(Object.values(filterOptions));

        setOptions({ section: props.section, activeColumn: columnName });
        setFilterOptions(sorted);
        toggleFilters(props.section); // open filters list
      } catch (error) {
        props.handleError(error, props.column.key);
      }
    },
    [props, getOptions, toggleFilters, setFilterOptions, setOptions, sortOptions],
  );

  const getMatch = useCallback(
    (item, key) => {
      try {
        return props.currentFilters[key].filterTerm.some((filterTerm) => {
          if (!isNaN(filterTerm.value) && !isNaN(parseInt(filterTerm.value))) {
            return parseFloat(item[key]) === parseFloat(filterTerm.value);
          }
          return item[key].toString().includes(filterTerm.value);
        });
      } catch (error) {
        handleError(error, props.column.key);
      }
    },
    [props],
  );

  /* ----- APPLY FILTERS ----- */
  useEffect(() => {
    try {
      let filters = localFilters;
      const keys = Object.keys(filters).filter((item) => (item || "").startsWith(`${props.section}_`));
      const indexOfFirst = keys.indexOf(key);

      if (indexOfFirst == 0) {
        keys.forEach((sectionKey) => {
          if (sectionKey == key) {
            const currentFilters = filters[sectionKey].map((item) => ({ label: item.label, value: (item.value || "").toString().trim() }));
            props.onChange({ filterTerm: currentFilters, column: props.column, rawValue: currentFilters, filterValues: () => {} });
            delete filters[sectionKey];
          }
        });
        setLocalFilters(filters);
      }
    } catch (error) {
      setLocalFilters({});
      props.handleError(error, props.column.key);
    }
  }, [localFilters, props]);

  /* ----- DETECT & CLEAR FILTERS ----- */
  useEffect(() => {
    try {
      const localStorageFilter = getStickyFilters(props.section);
      const localStorageFiltersCount = localStorageFilter.filter((item) => (item.value || []).length > 0).length;
      const selectedFiltersCount = (selectedFilters[key] || []).length;
      const currentFiltersCount = Object.keys(props.currentFilters).length;

      // this is triggered by clearing filters (button)
      if (selectedFiltersCount > 0 && localStorageFiltersCount == 0 && currentFiltersCount == 0) {
        const selected = selectedFilters;
        delete selected[key];
        setSelectedFilters(selected);

        props.onChange({ filterTerm: [], column: props.column, rawValue: [], filterValues: () => {} });
      }

      const localFiltersCount = (Object.keys(localFilters).filter((k) => (k || "").startsWith(`${props.section}_`)) || []).length;

      /* Reapply filters if there is localstorage and selected filters but no current filters applied (grid reloaded) */
      if (selectedFiltersCount > 0 && localStorageFiltersCount > 0 && currentFiltersCount == 0 && localFiltersCount == 0) {
        setReload(!reload);
      }

      const currentFilters = props.currentFilters[props.column.key] || { filterTerm: [] };
      const localStorageFilterKey = localStorageFilter.find((local) => local.field === props.column.key) || { value: [] };

      /* Filter removed from pill */
      if (selectedFiltersCount > currentFilters.filterTerm.length && localStorageFilterKey.value.length === currentFilters.filterTerm.length) {
        let selected = selectedFilters;
        delete selected[key];
        selected[key] = currentFilters.filterTerm;
        setSelectedFilters({ ...selected });
      }
    } catch (error) {
      props.handleError(error, props.column.key);
    }
  }, [props]);

  const badgeCount = useMemo(() => {
    try {
      return (selectedFilters[key] || []).length || 0;
    } catch (error) {
      handleError(error, props.column.key);
    }
  }, [selectedFilters]);

  return (
    <Badge badgeContent={badgeCount} onClick={() => setFilters(props.column.key)} style={{ top: 0, cursor: "pointer" }}>
      <IconFilter style={{ margin: "3px", color: badgeCount > 0 ? "orange" : "black" }} />
    </Badge>
  );
};

export default AutoCompleteFilter;
