import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

const endpoint = "variety";

export type VarietyType = {
  id?: number;
  code: string;
  name: string;
  commodity_id: string;
};

export const varieties = client(endpoint, (item) => ({
  ...item,
}));

export const varietyFull = () => {
  return request(`${endpoint}/extend/all`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

type VarietyExtCommodity = {
  id: number;
  code: string;
  name: string;
  var_comm: string;
  commodity: string;
  commodity_id: number;
  commodity_name: string;
};

export const varietiesWithCommodity = async (): Promise<VarietyExtCommodity[]> => {
  const result = await request(`${endpoint}/extend/varietiesWithCommodity`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const DistinctVarietyCodes = () => {
  return request(`${endpoint}/count/distinctCodes`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const DistinctVarietyNames = () => {
  return request(`${endpoint}/count/distinctNames`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const varietyAllSortedMappedforCombo = async () => {
  const result = await varieties.all().then((data) => {
    return data
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
      })
      .map((v) => {
        return { value: v.id, display: v.name + " (" + v.code + ")", data: v };
      });
  });
  return result;
};

export const VarietiesByCommodity = (commodity) => {
  return request(`${endpoint}/extend/varietiesByCommodity/${commodity}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const VarietiesByCommodityMappedForCombo = async (commodity) => {
  const result = await VarietiesByCommodity(commodity).then((data) => {
    return data.data
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 1;
      })
      .map((v) => {
        return { id: v.id, value: v.id, display: v.name + " (" + v.code + ")" };
      });
  });
  return result;
};

export const VarietiesByCommodityMappedForComboValue = async (commodity) => {
  const result = await VarietiesByCommodity(commodity).then((data) => {
    return data.data
      .sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0;
      })
      .map((v) => {
        return { id: v.id, value: v.code, display: v.name + " (" + v.code + ")" };
      });
  });
  return result;
};
